import { inject, Injectable } from '@angular/core';
import {
  ArchiveSubmissionResponse,
  ArchiveTemplateResponse,
  CreateSubmissionResponse,
  type GetSubmissionsResponse,
  type GetTemplatesResponse,
} from '@docuseal/api';
import { RcDocuseal } from 'rc-common';
import { map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RcApiService } from './rc-api.service';

@Injectable()
export class DocumentsService {
  private readonly rcApiService = inject(RcApiService);

  public getTemplates() {
    return this.rcApiService.http
      .get<GetTemplatesResponse>(`${environment.apiUrl}/documents/templates`)
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public archiveTemplate(templateId: number) {
    return this.rcApiService.http.delete<ArchiveTemplateResponse>(
      `${environment.apiUrl}/documents/templates/${templateId}`,
    );
  }

  public getSubmissions() {
    return this.rcApiService.http
      .get<GetSubmissionsResponse>(
        `${environment.apiUrl}/documents/submissions`,
      )
      .pipe(
        map((res) => {
          return res.data;
        }),
      );
  }

  public createSubmission(payload: RcDocuseal.CreateSubmissionDto) {
    return this.rcApiService.http.post<CreateSubmissionResponse>(
      `${environment.apiUrl}/documents/submissions`,
      payload,
    );
  }

  public archiveSubmission(submissionId: number) {
    return this.rcApiService.http.delete<ArchiveSubmissionResponse>(
      `${environment.apiUrl}/documents/submissions/${submissionId}`,
    );
  }

  public createTokenForExistingTemplate(templateId: number) {
    return this.rcApiService.http
      .get<{
        token: string;
      }>(`${environment.apiUrl}/documents/templates/${templateId}/token`)
      .pipe(map((res) => res.token));
  }

  public createTokenForNewTemplate(
    payload: RcDocuseal.CreateTokenForNewTemplateDto,
  ) {
    return this.rcApiService.http
      .post<{
        token: string;
      }>(`${environment.apiUrl}/documents/templates`, payload)
      .pipe(map((res) => res.token));
  }

  sendSignatureReminders(submissionId: number) {
    return this.rcApiService.http.post(
      `${environment.apiUrl}/documents/submissions/${submissionId}/sendReminders`,
      {},
    );
  }
}
